import { LineItem, Order } from '@commercetools/platform-sdk';
import { strict as assert } from 'assert';

export enum RetryStrategy {
  ALL_EVENTS = 'ALL_EVENTS',
  SKIP_ON_NEWER_EVENTS_FULFILLED = 'SKIP_ON_NEWER_EVENTS_FULFILLED',
  SKIP_ALL = 'SKIP_ALL',
}

export enum RecordStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IGNORED = 'IGNORED',
}

export const CUSTOMER_MIGRATION_RETRY_MOD = 'CUSTOMER_MIGRATION_RETRY_MOD';

// ES Methods mapping docs: https://pangaea.atlassian.net/wiki/spaces/REP/pages/4156424307/Payment+giftcard+flows
const adyenPaymentMethodToKafkaMap: Record<string, string> = {
  visa: 'credit_card',
  mc: 'credit_card',
  amex: 'credit_card',
  maestro: 'credit_card',
  discover: 'credit_card',
  jcb: 'credit_card',
  diners: 'credit_card',
  accel: 'credit_card',
  nyce: 'credit_card',
  pulse: 'credit_card',
  star: 'credit_card',
  paypal: 'paypal',
  klarna_account: 'klarna',
  klarna: 'klarna',
  givex: 'gift_card',
};

export const webCapturedPaymentMethods = ['paypal', 'gift_card'];

const storePaymentMethodModifier: Record<string, Record<string, string>> = {
  us: {
    paypal: 'paypal_auth',
  },
  ca: {
    paypal: 'paypal_auth',
  },
};

export function adyenPaymentMethodToKafkaMapper(method: string | undefined, store: string): string {
  assert(method, 'Payment method is required');
  const cardTypeFromMethod = method.split('_')?.[0]; // e.g. mc_applepay -> mc
  const resolvedMethod = adyenPaymentMethodToKafkaMap[method] || adyenPaymentMethodToKafkaMap[cardTypeFromMethod];
  return storePaymentMethodModifier[store]?.[resolvedMethod] || resolvedMethod;
}

export function getNsPaymentProcessor(nsPaymentMethod: string) {
  if (nsPaymentMethod === 'gift_card') return 'gift_card';
  return 'adyen';
}

export function newStorePaymentMethodMetadataMapper(method: string | undefined, store: string): string {
  assert(method, 'Payment method is required');
  const cardTypeFromMethod = method.split('_')?.[0]; // e.g. mc_applepay -> mc
  return storePaymentMethodModifier[store]?.[cardTypeFromMethod] || cardTypeFromMethod;
}

export const adyenWalletToKafkaMap: Record<string, string> = {
  applepay: 'Apple Pay',
  googlepay: 'Google Pay',
};

export function adyenWalletToKafkaMapper(method: string | undefined) {
  const defaultWallet = 'Direct';
  if (!method) return defaultWallet;
  return adyenWalletToKafkaMap[method] || defaultWallet;
}

export function functionNameToEnableVariable(functionName: string, envName: string) {
  const envVarName = functionName.replace(new RegExp(`^${envName}-`), '').replace(new RegExp(`-${envName}$`), '');

  return `${envVarName.toUpperCase()}_ENABLED`;
}

export function createEmailOrderUrl(orderNumber: string, postCode: string, storeKey: string, locale: string): string {
  const { STOREFRONT_URL, OUTLET_STOREFRONT_URL } = process.env;
  assert(STOREFRONT_URL, 'STOREFRONT_URL env var must be present');
  assert(OUTLET_STOREFRONT_URL, 'OUTLET_STOREFRONT_URL env var must be present');

  return `${encodeURI(
    `${
      storeKey === 'outlet-uk' ? OUTLET_STOREFRONT_URL : `${STOREFRONT_URL}/${locale}`
    }/my-account/my-orders/${orderNumber}?postCode=${postCode}`,
  )}`;
}

export function getCTLine(item: any, lineIndexMap: Record<string, number>, order: Order) {
  const sku = item.product_id;
  const qty = item.quantity;
  const index = lineIndexMap[`${sku}-${qty}`] !== undefined ? lineIndexMap[`${sku}-${qty}`] + 1 : 0;
  // eslint-disable-next-line
  lineIndexMap[`${sku}-${qty}`] = index;
  const lines = order.lineItems.filter((line) => line.variant.sku === sku && line.quantity === qty);
  if (lines.length < index + 1) {
    const errorMsg = `Order ${
      order.orderNumber
    } does not have enough line items with sku ${sku}, quantity ${qty}. Found ${lines.length}, expected >= ${
      index + 1
    }`;
    throw new Error(errorMsg);
  }
  return lines[index];
}

export const getVariantAttributeFromOrder = (attributeName: string, ctLine: LineItem) =>
  ctLine.variant.attributes?.find((attribute) => attribute.name === attributeName)?.value ?? '';
