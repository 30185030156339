import { useEffect, useRef } from 'react';
import { media } from '../stylings';
import { useMediaMatch } from './useMediaMatch';

export function useDisableBodyScroll(disable: boolean) {
  const scrollGutter = useRef(0);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));

  useEffect(() => {
    scrollGutter.current = window.innerWidth - document.documentElement.clientWidth;
  }, [isDesktop]);

  useEffect(() => {
    document.body.style.overflow = disable ? 'hidden' : 'initial';
    document.body.style.marginRight = disable ? `${scrollGutter.current}px` : 'initial';

    return () => {
      document.body.style.overflow = 'initial';
      document.body.style.marginRight = 'initial';
    };
  }, [disable]);
}
