import type {
  CentPrecisionMoney,
  PrimaryCategory,
  ProductBadgeV2,
  ProductPromotion,
  StyleProduct,
} from '../../codegen/types';

export type ProductHitTypes = {
  name: string;
  description: string;
  slug: string;
  gender: string[];
  category: string;
  fastening: string;
  technologies: string[];
  color: string;
  colors: StyleProduct[];
  key: string;
  price: CentPrecisionMoney;
  wasPrice: CentPrecisionMoney;
  thenPrice: CentPrecisionMoney;
  objectID: string;
  image: string[];
  merchandiseCategory: string;
  rangeGroupDescription: string;
  brand: string;
  primaryCategory: PrimaryCategory;
  badgesV2: ProductBadgeV2;
  styleName: string;
  promotion?: ProductPromotion;
};

export const getLocalizedValue = <T = string, Y = unknown>(localizedValue: Y): T => localizedValue as unknown as T;
