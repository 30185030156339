import { css } from 'styled-components';
import { media } from './media';

export interface HEXColours {
  [key: string]: string;
}

export const colours: HEXColours = {
  // Neutrals
  BLACK: '#000000',
  DARK_GREY: '#333333',
  DIM_GREY: '#696969',
  NOBEL_GREY: '#9B9B9B',
  MEDIUM_GREY: '#e5e5e5',
  LIGHT_GREY: '#ececec',
  LIGHTER_GREY: '#f3f3f3',
  PALE_GREY: '#f7f7f7',
  ALABASTER_WHITE: '#f9f9f9',
  WHITE: '#ffffff',
  DARK_ORANGE: '#FF8C00',

  // Special Supporting Color
  TURQUOISE: '#5AEECC',
  SKY_BLUE: '#00ccff',

  // Primary
  DARK_BLUE: '#0A223E',
  LIGHT_BLUE: '#E8F2FB',
  NAVY_BLUE: '#193D69',

  // Secondary
  GREY: '#575757',
  MID_GREY: '#8E8E8E',

  // Tint & Shades
  LIGHT_GREY_2: '#F5F5F5',
  VERY_LIGHT_GREY: '#FAFAFA',
  TINT_BLUE: '#F8FCFF',
  DARK_BLUE_TINT: '#1A3D69',

  // Success
  SUCCESS_PRIMARY: '#2C8800',
  SUCCESS_SECONDARY: '#CCDFC3',

  // Warning
  WARNING_PRIMARY: '#D99935',
  WARNING_SECONDARY: '#FDF5E2',

  // Error
  ERROR_PRIMARY: '#C50000',
  ERROR_SECONDARY: '#FCE7E7',

  // Discount
  DISCOUNT_RED: '#D60505',

  // Others
  LIGHT_CYAN: '#d8f8f8',
  LIGHT_CORAL: '#ec7d7d',
  LIGHT_GREEN: '#8ae98a',
  RED: '#ff0000',
  PIXIE_GREEN: '#CCDFC3',
  VIVID_SKY: '#00ccfe',
  ONETRUST_GREEN: '#6cc04a',
  ONETRUST_POWEREDBY: '#3c474c',
  ONETRUSTCTA_BACKGROUND: '#eeeeee',
  ONETRUSTCTA_BORDER: '#aaaaaa',
  TABLE_BORDER: '#e1e1e1',
  TABLE_HEADING: '#f6f6f4',
  OUTLINE_BORDER: '#6f5dc7',
};

const enum Theme {
  DARK = 'Dark',
  LIGHT = 'Light',
}

export const BGThemeColour = css<{ $desktopTheme?: string; $mobileTheme?: string }>`
  background-color: ${({ $mobileTheme }) => ($mobileTheme === Theme.DARK ? colours.BLACK : colours.VERY_LIGHT_GREY)};

  @media ${media.greaterThan('lg')} {
    background-color: ${({ $desktopTheme }) =>
      $desktopTheme === Theme.DARK ? colours.BLACK : colours.VERY_LIGHT_GREY};
  }
`;

export const TextThemeColour = css<{ $desktopTheme?: string; $mobileTheme?: string }>`
  color: ${({ $mobileTheme }) => ($mobileTheme === Theme.DARK ? colours.WHITE : colours.BLACK)};
  &:focus-visible {
    outline-color: ${colours.DARK_ORANGE};
  }

  @media ${media.greaterThan('lg')} {
    color: ${({ $desktopTheme }) => ($desktopTheme === Theme.DARK ? colours.WHITE : colours.BLACK)};
  }
`;

export const BorderThemeColour = css<{ $desktopTheme?: string; $mobileTheme?: string }>`
  border-color: ${({ $mobileTheme }) => ($mobileTheme === Theme.DARK ? colours.WHITE : colours.BLACK)};

  @media ${media.greaterThan('lg')} {
    border-color: ${({ $desktopTheme }) => ($desktopTheme === Theme.DARK ? colours.WHITE : colours.BLACK)};
  }
`;

export const colorNameToHex: { [key: string]: string } = {
  beige: '#F5F5DC',
  black: '#000000',
  'black leather': '#000000',
  blue: '#0000FF',
  brown: '#A52A2A',
  burgundy: '#800020',
  charcoal: '#808080',
  'copper leather': '#B87333',
  gold: '#FFD700',
  green: '#008000',
  grey: '#808080',
  metallic: '#ffffff',
  multi: '#ffffff',
  orange: '#FFA500',
  pink: '#FFC0CB',
  purple: '#800080',
  red: '#FF0000',
  silver: '#C0C0C0',
  white: '#ffffff',
  yellow: '#FFFF00',
  noir: '#000000',
  'cuir noir': '#000000',
  bleu: '#0000FF',
  brun: '#A52A2A',
  bourgogne: '#800020',
  charbon: '#808080',
  'cuir cuivre': '#B87333',
  doree: '#FFD700',
  vert: '#008000',
  gris: '#808080',
  métallique: '#ffffff',
  rose: '#FFC0CB',
  violet: '#800080',
  rouge: '#FF0000',
  argent: '#C0C0C0',
  blanc: '#ffffff',
  jaune: '#FFFF00',
  schwarz: '#000000',
  'schwarzes Leder': '#000000',
  blau: '#0000FF',
  braun: '#A52A2A',
  burgund: '#800020',
  holzkohle: '#808080',
  kupferleder: '#B87333',
  grün: '#008000',
  grau: '#808080',
  metallisch: '#ffffff',
  rosa: '#FFC0CB',
  lila: '#800080',
  rot: '#FF0000',
  silber: '#C0C0C0',
  weiß: '#ffffff',
  gelb: '#FFFF00',
  nero: '#000000',
  'pelle nera': '#000000',
  blu: '#0000FF',
  marrone: '#A52A2A',
  bordeaux: '#800020',
  carbonio: '#808080',
  'pelle ramata': '#B87333',
  oro: '#FFD700',
  verde: '#008000',
  grigio: '#808080',
  metallico: '#ffffff',
  arancia: '#FFA500',
  viola: '#800080',
  rosso: '#FF0000',
  argento: '#C0C0C0',
  bianco: '#ffffff',
  giallo: '#FFFF00',
  negro: '#000000',
  'cuero negro': '#000000',
  azul: '#0000FF',
  marrón: '#A52A2A',
  borgoña: '#800020',
  carbón: '#808080',
  'cuero de cobre': '#B87333',
  metálico: '#ffffff',
  naranja: '#FFA500',
  púrpura: '#800080',
  rojo: '#FF0000',
  plata: '#C0C0C0',
  blanco: '#ffffff',
  amarillo: '#FFFF00',
  zwart: '#000000',
  'zwart leer': '#000000',
  blauw: '#0000FF',
  bruin: '#A52A2A',
  houtskool: '#808080',
  'koper leer': '#B87333',
  goud: '#FFD700',
  groente: '#008000',
  grijs: '#808080',
  metalen: '#ffffff',
  oranje: '#FFA500',
  roze: '#FFC0CB',
  paars: '#800080',
  rood: '#FF0000',
  zilver: '#C0C0C0',
  wit: '#ffffff',
  geel: '#FFFF00',
};
