import type { ReactElement } from 'react';
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { serializeError } from 'serialize-error';
import type { CartLayoutSlot } from '@amplience/content-types/typings/s-cart-layout';
import {
  CartContextProvider,
  LocaleContext,
  NeedHelpContextProvider,
  type NeedHelpContextType,
  UserContextProvider,
  isStar2Check,
  reloadOneTrustBanner,
} from '@storefront/lib-global';
import { CALLBACK_PATH, START_CSA_SESSION_PATH, TERMINATE_CSA_SESSION_PATH } from '@storefront/lib-global/auth';
import type {
  GetFeatureFlagsResponse,
  MarketingPreferencesResponse,
  PaymentMethod,
  SiteSwitchResponse,
  Store,
} from '@storefront/lib-global/codegen/types';
import { CsaContextProvider } from '@storefront/lib-global/context/csaContext';
import { FeatureFlagsProvider } from '@storefront/lib-global/context/featureFlagsContext';
import { HistoryProvider } from '@storefront/lib-global/context/historyContext';
import { MasterLayoutProvider } from '@storefront/lib-global/context/masterLayoutContext';
import type { MasterLayoutContextType } from '@storefront/lib-global/context/masterLayoutContext';
import { RecaptchaProvider } from '@storefront/lib-global/context/recaptchaContext';
import { StaticContextProvider } from '@storefront/lib-global/context/staticContext';
import { ErrorFallback } from '@storefront/lib-global/globalComponents/errorFallback';
import type { HandlebarTemplateProps } from '@storefront/lib-global/globalComponents/handlebarTemplate/HandlebarTemplate';
import { NextNProgressBar } from '@storefront/lib-global/globalComponents/progressBar';
import { ApolloProvider, initializeApollo } from '@storefront/lib-global/services/gql';
import { ResetCSS } from '@storefront/lib-global/stylings/reset';
import { Locale, getCtLocale } from '@storefront/lib-global/utils/internationalization';
import { isAmpliencePreviewEnabled } from '@storefront/lib-global/utils/isAmpliencePreviewEnabled';
import { logger } from '@storefront/lib-global/utils/logger';
import { onReportWebVitals } from '@storefront/lib-global/utils/onReportWebVitals';
import { STOREFRONT_CDN_URL, UK_OUTLET_CDN_URL, isCsaEnv } from '@storefront/lib-global/utils/ssr';

interface MyAppProps extends AppProps {
  host: string;
  baseUrl: string;
  featureFlags: GetFeatureFlagsResponse;
  masterLayoutData: MasterLayoutContextType['masterLayoutData'];
  masterLayoutHandlebarComponents: HandlebarTemplateProps[] | null;
  navigationHierarchyData: MasterLayoutContextType['navigationHierarchyData'];
  paymentMethods: PaymentMethod[];
  marketingPreferences: MarketingPreferencesResponse;
  storeData: Store;
  isCSA: boolean;
  siteSwitch: SiteSwitchResponse[];
  needHelpData: NeedHelpContextType['needHelpData'] | null;
}

/* eslint-disable import/no-extraneous-dependencies */
if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  (async () => {
    const React = await import('react');
    const ReactDOM = await import('react-dom');
    const axe = await import('@axe-core/react');
    const { config, options } = await import('@config/axe-config');
    axe.default(React.default, ReactDOM.default, 1000, {
      ...config,
      ...options,
    });
  })();
}

const CSAComponent = lazy(async () => {
  const importedModule = await import(/* webpackChunkName: "lib-csa" */ '@storefront/lib-csa/components/section');

  return {
    default: importedModule.CsaSection,
  };
});

function App({
  Component,
  host,
  baseUrl,
  pageProps,
  featureFlags,
  masterLayoutData,
  masterLayoutHandlebarComponents,
  navigationHierarchyData,
  paymentMethods,
  marketingPreferences,
  storeData,
  isCSA,
  siteSwitch,
  needHelpData,
}: MyAppProps): ReactElement {
  const [csaEmail, setCsaEmail] = useState<string>('');
  const router = useRouter();
  const isTerminateCsaSessionPage = router.pathname === TERMINATE_CSA_SESSION_PATH;
  const { locale } = router;

  const localeValue = useMemo(
    () => ({
      locale: locale ?? Locale.DEFAULT_LOCALE,
    }),
    [locale],
  );

  const handleFetchCsaUserData = useCallback(async () => {
    const isCallbackPage = router.pathname === CALLBACK_PATH;
    const isStartCsaSessionPage = router.pathname === START_CSA_SESSION_PATH;

    try {
      const response = await fetch('/api/csaUserData');
      const { userId, isCsaLoggedIn } = await response.json();
      setCsaEmail(userId);

      if (!isCsaLoggedIn && !isCallbackPage && !isStartCsaSessionPage) {
        router.push(START_CSA_SESSION_PATH, undefined, { locale: router.locale });
      }
    } catch (error) {
      console.error('Error fetching csaUserData:', error);
    }
  }, [router]);

  useEffect(() => {
    if (!isCSA) {
      history.scrollRestoration = 'manual';
    } else if (!isTerminateCsaSessionPage) {
      (async () => {
        handleFetchCsaUserData();
      })();
    }
  }, [isCSA, isTerminateCsaSessionPage, handleFetchCsaUserData]);

  useEffect(() => {
    // This should be called whenever a page change
    reloadOneTrustBanner();
  }, [router]);

  return (
    <>
      <NextNProgressBar />
      <ResetCSS />
      <FeatureFlagsProvider featureFlags={featureFlags}>
        <CsaContextProvider isCSA={isCSA}>
          <ApolloProvider>
            <MasterLayoutProvider
              masterLayoutData={masterLayoutData}
              masterLayoutHandlebarComponents={masterLayoutHandlebarComponents}
              navigationHierarchyData={navigationHierarchyData}
            >
              <StaticContextProvider
                host={host}
                baseUrl={baseUrl}
                marketingPreferences={marketingPreferences}
                paymentMethods={paymentMethods}
                storeData={storeData}
                switchSitesData={siteSwitch}
              >
                <NeedHelpContextProvider needHelpData={needHelpData}>
                  <RecaptchaProvider>
                    <UserContextProvider>
                      <LocaleContext.Provider value={localeValue}>
                        <CartContextProvider>
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            {isCSA && !isTerminateCsaSessionPage && !!csaEmail && (
                              <Suspense fallback={<div>Loading...</div>}>
                                <CSAComponent csaEmail={csaEmail} />
                              </Suspense>
                            )}
                            <HistoryProvider>
                              <Component {...pageProps} />
                            </HistoryProvider>
                          </ErrorBoundary>
                        </CartContextProvider>
                      </LocaleContext.Provider>
                    </UserContextProvider>
                  </RecaptchaProvider>
                </NeedHelpContextProvider>
              </StaticContextProvider>
            </MasterLayoutProvider>
          </ApolloProvider>
        </CsaContextProvider>
      </FeatureFlagsProvider>
    </>
  );
}

App.getInitialProps = async ({ router, ctx }: AppContext) => {
  const { locale } = router;

  const apolloClient = initializeApollo(ctx.req, ctx.res);

  const ctLocale = getCtLocale(locale);

  const { host } = ctx.req?.headers || {};
  const isOutlet = ctx.req?.headers['x-forwarded-host']?.includes('outlet');
  const baseUrl = (isOutlet ? `https://${UK_OUTLET_CDN_URL}` : `${STOREFRONT_CDN_URL}/${locale}`) || '';

  const [
    getMasterLayout,
    getHandlebarTemplateComponents,
    getStaticContextData,
    getAppFeatureFlags,
    { isBusinessComponent, getListOfHandlebarComponents },
    getSlotLayout,
  ] = await Promise.all([
    import('@storefront/lib-global/services/amplience/getMasterLayout').then((mod) => mod.getMasterLayout),
    import('@storefront/lib-global/services/amplience/getHandlebarTemplateComponents').then(
      (mod) => mod.getHandlebarTemplateComponents,
    ),
    import('@storefront/lib-global/services/gql/getStaticContextData').then((mod) => mod.getStaticContextData),
    import('@storefront/lib-global/services/feature-flags').then((mod) => mod.getAppFeatureFlags),
    import('@storefront/lib-global/utils/amplienceUtils').then((mod) => ({
      isBusinessComponent: mod.isBusinessComponent,
      getListOfHandlebarComponents: mod.getListOfHandlebarComponents,
    })),
    import('@storefront/lib-global/services/amplience/getSlotLayout').then((mod) => mod.getSlotLayout),
  ]);

  try {
    const [
      { masterLayoutData, navigationHierarchyData },
      { paymentMethods, marketingPreferences, storeData, siteSwitch },
      featureFlags,
      cartLayoutData,
    ] = await Promise.all([
      getMasterLayout({
        locale: locale || 'en',
        staging: isAmpliencePreviewEnabled(),
      }),
      getStaticContextData(apolloClient, ctLocale, locale),
      getAppFeatureFlags(apolloClient, locale || Locale.DEFAULT_LOCALE),
      getSlotLayout(locale ?? Locale.DEFAULT_LOCALE, 'https://clarks.com/slot/s-cart-layout.json'),
    ]);

    const masterLayoutMarketingMessage = masterLayoutData?.headerComponent?.marketingMessage;
    const masterLayoutNotificationBar = masterLayoutData?.headerComponent?.notificationBar;
    const masterLayoutBusinessSlot = masterLayoutData?.footerComponent?.businessSlot;

    const masterLayoutBusinessComponents = getListOfHandlebarComponents([
      ...(isBusinessComponent(masterLayoutMarketingMessage) ? [masterLayoutMarketingMessage] : []),
      ...(isBusinessComponent(masterLayoutNotificationBar) ? [masterLayoutNotificationBar] : []),
      ...(isBusinessComponent(masterLayoutBusinessSlot) ? [masterLayoutBusinessSlot] : []),
    ]);

    const masterLayoutHandlebarComponents = await getHandlebarTemplateComponents(masterLayoutBusinessComponents ?? []);

    const needHelpData =
      !!cartLayoutData && isStar2Check({ locale }) ? (cartLayoutData as CartLayoutSlot).needHelpSection : null;

    return {
      host,
      baseUrl,
      masterLayoutData,
      masterLayoutHandlebarComponents,
      navigationHierarchyData,
      marketingPreferences,
      paymentMethods,
      storeData,
      isCSA: isCsaEnv(),
      siteSwitch,
      featureFlags,
      needHelpData,
    };
  } catch (err) {
    // TODO: Error handling
    logger.error({ err: serializeError(err) }, 'Error in getInitialProps');
    return {};
  }
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  onReportWebVitals(metric);
}

// https://github.com/i18next/next-i18next/issues/1944
export default appWithTranslation<never>(App);

// Force Build
