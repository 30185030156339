import { createContext, useContext, useState } from 'react';
import type { PropsWithChildren } from 'react';
import type { GetFeatureFlagsResponse } from '../codegen/types';

export interface FeatureFlagsSimplifiedContext {
  isAuthRecaptchaEnabled: boolean;
  isOrderRiskInquiryEnabled: boolean;
  isKlarnaPdpEnabled: boolean;
  isProductStockCheckEnabled: boolean;
}

interface FeatureFlagsContext {
  featureFlags: GetFeatureFlagsResponse;
}

export const FeatureFlagsContext = createContext<FeatureFlagsSimplifiedContext>({
  isAuthRecaptchaEnabled: false,
  isOrderRiskInquiryEnabled: false,
  isKlarnaPdpEnabled: false,
  isProductStockCheckEnabled: false,
});

export function FeatureFlagsProvider({ children, featureFlags }: PropsWithChildren<FeatureFlagsContext>) {
  const [contextData] = useState<FeatureFlagsSimplifiedContext>({
    isAuthRecaptchaEnabled: featureFlags?.authRecaptcha?.enabled || false,
    isOrderRiskInquiryEnabled: featureFlags?.orderRiskInquiry?.enabled || false,
    isKlarnaPdpEnabled: featureFlags?.klarnaPdp?.enabled || false,
    isProductStockCheckEnabled: featureFlags?.productStockCheck?.enabled || false,
  });

  return <FeatureFlagsContext.Provider value={contextData}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlagsContext() {
  const { isAuthRecaptchaEnabled, isOrderRiskInquiryEnabled, isKlarnaPdpEnabled, isProductStockCheckEnabled } =
    useContext(FeatureFlagsContext);

  return {
    isAuthRecaptchaEnabled,
    isOrderRiskInquiryEnabled,
    isKlarnaPdpEnabled,
    isProductStockCheckEnabled,
  };
}
