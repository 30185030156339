import { strict as assert } from 'assert';

export enum DiscountLevel {
  SHIPPING = 'shipping',
  ORDER = 'order',
}

export interface Discount {
  id: string;
  coupon_code?: string;
  externalRef?: string;
  reason?: string;
  value?: number;
  priceAdjustment?: number;
  level?: DiscountLevel | string;
}

export type ItemDiscount = Discount;

export interface Item {
  discounts: Discount[];
}

function getDiscountText(discount: Discount) {
  return discount.reason || discount?.coupon_code || discount?.externalRef;
}

export function mapDiscounts(item: Item | undefined | null): (string | undefined)[] | undefined {
  if (item) {
    const discounts = item.discounts?.map((discount: Discount) => getDiscountText(discount)).filter((value) => value);
    return discounts?.length ? discounts : undefined;
  }
  return undefined;
}

/**
 * Maps discounts to the discounted value and filters out shipping related discounts.
 */
export function mapDiscountValues(item: Item | undefined | null): number[] | undefined {
  if (!item || !item.discounts) {
    return undefined;
  }

  const discounts = item.discounts
    .filter((discount: Discount) => discount.level !== DiscountLevel.SHIPPING && discount.priceAdjustment)
    .map((discount: Discount) => discount?.priceAdjustment ?? 0);

  return discounts?.length ? discounts : undefined;
}

/**
 * Maps shipping discounts of all order items, dedupes them by discount id.
 */
export function mapShippingDiscountValue(items: Item[]): number {
  const discountMap = new Map<string, Discount>();

  for (const item of items) {
    for (const discount of item.discounts || []) {
      if (discount.level === DiscountLevel.SHIPPING && discount.priceAdjustment) {
        discountMap.set(discount.id, discount);
      }
    }
  }

  return Array.from(discountMap.values()).reduce((priceAdjustment, discount) => {
    return priceAdjustment + (discount.priceAdjustment || 0);
  }, 0);
}

export function localizeDate(date: string | null | undefined, locale: string | null | undefined): string {
  assert(locale, 'Locale must be provided');
  assert(date, 'Date must be provided');
  return new Date(date).toLocaleString(locale, {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatValue(
  value: string | number | null | undefined,
  currency: string | null | undefined,
  currencySymbol?: string,
): string | undefined {
  assert(currency, 'Currency must be provided');
  let formattedValue;
  if (value != null) {
    const fixedValue = value === 0 ? '0.00' : (Math.round(+value * 100) / 100).toFixed(2);
    if (currencySymbol) {
      formattedValue = `${currencySymbol}${fixedValue}`;
    } else {
      formattedValue = `${fixedValue} ${currency}`;
    }
  }
  return formattedValue;
}

export function formatValueWithQuantity(
  value: string | number,
  quantity: string | null | undefined | number,
  currency: string | null | undefined,
  currencySymbol?: string,
): string | undefined {
  const numValue = typeof value === 'string' ? parseFloat(value) : value;
  const numQuantity = typeof quantity === 'string' ? parseFloat(quantity) : quantity || 1;

  return formatValue(numValue * numQuantity, currency, currencySymbol);
}

export const UK_COUNTRIES = ['GB', 'GG', 'JE', 'IM'];

export function generateLoginLink(channel: string, locale: string): string {
  return `${mapChannelToDomainUrl(channel, locale)}/login`;
}

export function generateProductLink(channel: string, locale: string, sku: string | null | undefined) {
  assert(sku, 'Product sku must be provided');
  const productCode = sku.length < 8 ? sku : sku.substring(0, 8);

  return `${mapChannelToDomainUrl(channel, locale)}/${productCode}-p`;
}

export function mapChannelToDomainUrl(channel: string, locale: string): string {
  switch (channel) {
    case 'outlet':
      return outletStorefrontUrl();
    case 'fullPrice':
      return `${storefrontUrl()}/${locale}`;
    default:
      throw new Error(`Channel ${channel} is not supported`);
  }
}

function storefrontUrl(): string {
  const url = process.env.STOREFRONT_URL;
  assert(url, 'STOREFRONT_URL must be provided');
  return url;
}

function outletStorefrontUrl(): string {
  const url = process.env.OUTLET_STOREFRONT_URL;
  assert(url, 'OUTLET_STOREFRONT_URL must be provided');
  return url;
}

interface OrderQueryResponseItem {
  shippingServiceLevel?: string | null;
  status?: string | null;
}

export function isEGiftCardOnlyOrder(items?: OrderQueryResponseItem[]): boolean {
  if (!items || items.length === 0) return false;
  const notCancelledItems = items.filter((item) => item.status !== 'cancelled');
  if (notCancelledItems.length === 0) return false;
  // https://pangaea.atlassian.net/browse/REP-13072?focusedCommentId=294656
  return notCancelledItems.every((item) => item.shippingServiceLevel?.startsWith('EMAIL_'));
}
