import { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { media } from './media';

export const fontWeights = {
  SemiBold600: css`
    font-weight: 600;
  `,
  Medium500: css`
    font-weight: 500;
  `,
  Regular400: css`
    font-weight: 400;
  `,
  Light300: css`
    font-weight: 300;
  `,
};

export const fonts = {
  Montserrat: css`
    font-family: 'Montserrat', 'Montserrat-fallback', 'Montserrat-fallback-android', sans-serif;
  `,
  Oswald: css`
    font-family: 'Oswald', 'Oswald-fallback', 'Oswald-fallback-android', sans-serif;
  `,
  OpenSans: css`
    font-family: 'Open Sans', 'OpenSans-fallback', 'OpenSans-fallback-android', sans-serif;
  `,
};

export const bodyCopySize = {
  Medium: css`
    font-size: 16px;
    line-height: 28px;

    @media ${media.greaterThan('lg')} {
      font-size: 18px;
      line-height: 30px;
    }
  `,
  Small: css`
    font-size: 14px;
    line-height: 24px;

    @media ${media.greaterThan('lg')} {
      font-size: 16px;
      line-height: 28px;
    }
  `,
  Tiny: css`
    font-size: 12px;
    line-height: 20px;

    @media ${media.greaterThan('lg')} {
      font-size: 12px;
      line-height: 22px;
    }
  `,
};

export const TypographyStyles = {
  Headings: {
    XXLarge: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 72px;
      letter-spacing: -1.5px;
      line-height: 64px;

      @media ${media.greaterThan('lg')} {
        font-size: 128px;
        letter-spacing: -3px;
        line-height: 116px;
      }
    `,
    XLarge: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 56px;
      letter-spacing: -1px;
      line-height: 50px;

      @media ${media.greaterThan('lg')} {
        font-size: 92px;
        letter-spacing: -2px;
        line-height: 84px;
      }
    `,
    H1: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 40px;
      letter-spacing: -0.5px;
      line-height: 38px;

      @media ${media.greaterThan('lg')} {
        font-size: 64px;
        letter-spacing: -1.5px;
        line-height: 58px;
      }
    `,
    H2: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 30px;
      letter-spacing: -0.5px;
      line-height: 30px;

      @media ${media.greaterThan('lg')} {
        font-size: 44px;
        letter-spacing: -1px;
        line-height: 44px;
      }
    `,
    H3: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 24px;
      letter-spacing: -0.5px;
      line-height: 24px;

      @media ${media.greaterThan('lg')} {
        font-size: 34px;
        line-height: 34px;
      }
    `,
    H4: css`
      ${fonts.Oswald}
      ${fontWeights.Regular400}
      font-size: 18px;
      line-height: 18px;

      @media ${media.greaterThan('lg')} {
        font-size: 24px;
        line-height: 24px;
      }
    `,
    H4SemiBold: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 18px;
      line-height: 18px;

      @media ${media.greaterThan('lg')} {
        font-size: 24px;
        line-height: 24px;
      }
    `,
    H5: css`
      ${fonts.Montserrat}
      ${fontWeights.SemiBold600}
      font-size: 18px;
      line-height: 26px;

      @media ${media.greaterThan('lg')} {
        font-size: 20px;
        line-height: 28px;
      }
    `,
    H5Strikethrough: css`
      ${fonts.Montserrat}
      ${fontWeights.SemiBold600}
      font-size: 18px;
      line-height: 26px;
      text-decoration: line-through;

      @media ${media.greaterThan('lg')} {
        font-size: 20px;
        line-height: 28px;
      }
    `,
  },
  Body: {
    Medium: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        ${bodyCopySize.Medium}
      `,
      Medium: css`
        ${fonts.Montserrat}
        ${fontWeights.Medium500}
        ${bodyCopySize.Medium}
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        ${bodyCopySize.Medium}
      `,
      RegularStrikethrough: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        ${bodyCopySize.Medium}
        text-decoration: line-through;
      `,
    },
    Small: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        ${bodyCopySize.Small}
      `,
      Medium: css`
        ${fonts.Montserrat}
        ${fontWeights.Medium500}
        ${bodyCopySize.Small}
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        ${bodyCopySize.Small}
      `,
      RegularStrikethrough: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        ${bodyCopySize.Small}
        text-decoration: line-through;
      `,
      Light: css`
        ${fonts.Montserrat}
        ${fontWeights.Light300}
        ${bodyCopySize.Small}
      `,
    },
    Tiny: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        ${bodyCopySize.Tiny}
      `,
      Medium: css`
        ${fonts.Montserrat}
        ${fontWeights.Medium500}
        ${bodyCopySize.Tiny}
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        ${bodyCopySize.Tiny}
      `,
    },
  },
  Label: {
    Large: {
      Regular: css`
        ${fonts.Oswald}
        ${fontWeights.Regular400}
        font-size: 14px;
        line-height: 16px;
      `,
      Medium: css`
        ${fonts.Oswald}
        ${fontWeights.Medium500}
        font-size: 14px;
        line-height: 16px;
      `,
    },
    Small: {
      Regular: css`
        ${fonts.Oswald}
        ${fontWeights.Regular400}
        font-size: 12px;
        line-height: 14px;

        @media ${media.greaterThan('lg')} {
          font-size: 12px;
          line-height: 14px;
        }
      `,
      Medium: css`
        ${fonts.Oswald}
        ${fontWeights.Medium500}
        font-size: 12px;
        line-height: 14px;

        @media ${media.greaterThan('lg')} {
          font-size: 12px;
          line-height: 14px;
        }
      `,
      SemiBold: css`
        ${fonts.Oswald}
        ${fontWeights.SemiBold600}
        font-size: 12px;
        line-height: 14px;

        @media ${media.greaterThan('lg')} {
          font-size: 12px;
          line-height: 14px;
        }
      `,
    },
  },
  CallToAction: {
    Large: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        font-size: 14px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 16px;
          line-height: 20px;
        }
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        font-size: 14px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 16px;
          line-height: 20px;
        }
      `,
    },
    Small: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        font-size: 13px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 13px;
          line-height: 16px;
        }
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        font-size: 13px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 13px;
          line-height: 16px;
        }
      `,
    },
    Tiny: {
      SemiBold: css`
        ${fonts.Montserrat}
        ${fontWeights.SemiBold600}
        font-size: 12px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 12px;
          line-height: 16px;
        }
      `,
      Regular: css`
        ${fonts.Montserrat}
        ${fontWeights.Regular400}
        font-size: 12px;
        line-height: 18px;

        @media ${media.greaterThan('lg')} {
          font-size: 12px;
          line-height: 16px;
        }
      `,
    },
  },
  Button: {
    SemiBold: css`
      ${fonts.Oswald}
      ${fontWeights.SemiBold600}
      font-size: 14px;
      line-height: 20px;

      @media ${media.greaterThan('lg')} {
        font-size: 16px;
        line-height: 22px;
      }
    `,
    MontserratSemiBold: css`
      ${fonts.Montserrat}
      ${fontWeights.SemiBold600}
      font-size: 14px;
      line-height: 20px;
    `,
  },
  Summary: {
    SubTotal: css`
      ${fonts.Montserrat}
      ${fontWeights.Medium500}
      font-size: 14px;
      line-height: 20px;

      @media ${media.greaterThan('lg')} {
        font-size: 16px;
        line-height: 28px;
      }
    `,
  },
} as const;

export enum TypographyStylesType {
  HEADINGS_XX_LARGE = 'Headings - XXLarge',
  HEADINGS_X_LARGE = 'Headings - XLarge',
  HEADINGS_H1 = 'Headings - H1',
  HEADINGS_H2 = 'Headings - H2',
  HEADINGS_H3 = 'Headings - H3',
  HEADINGS_H4 = 'Headings - H4',
  HEADINGS_H4_SEMIBOLD = 'Headings - H4 - SemiBold',
  HEADINGS_H5 = 'Headings - H5',
  HEADINGS_H5_STRIKETHROUGH = 'Headings - H5 - Strikethrough',
  BODY_MEDIUM_SEMIBOLD = 'Body - Medium - SemiBold',
  BODY_MEDIUM_MEDIUM = 'Body - Medium - Medium',
  BODY_MEDIUM_REGULAR = 'Body - Medium - Regular',
  BODY_MEDIUM_REGULAR_STRIKETHROUGH = 'Body - Medium - Regular - Strikethrough',
  BODY_SMALL_SEMIBOLD = 'Body - Small - SemiBold',
  BODY_SMALL_MEDIUM = 'Body - Small - Medium',
  BODY_SMALL_REGULAR = 'Body - Small - Regular',
  BODY_SMALL_REGULAR_STRIKETHROUGH = 'Body - Small - Regular - Strikethrough',
  BODY_SMALL_LIGHT = 'Body - Small - Light',
  BODY_TINY_SEMIBOLD = 'Body - Tiny - SemiBold',
  BODY_TINY_MEDIUM = 'Body - Tiny - Medium',
  BODY_TINY_REGULAR = 'Body - Tiny - Regular',
  LABEL_LARGE_MEDIUM = 'Label - Large - Medium',
  LABEL_LARGE_REGULAR = 'Label - Large - Regular',
  LABEL_SMALL_SEMIBOLD = 'Label - Small - SemiBold',
  LABEL_SMALL_MEDIUM = 'Label - Small - Medium',
  LABEL_SMALL_REGULAR = 'Label - Small - Regular',
  CTA_LARGE_SEMIBOLD = 'CTA - Large - SemiBold',
  CTA_LARGE_REGULAR = 'CTA - Large - Regular',
  CTA_TINY_SEMIBOLD = 'CTA - Tiny - SemiBold',
  CTA_TINY_REGULAR = 'CTA - Tiny - Regular',
  BUTTON_SEMIBOLD = 'Button - SemiBold',
  SUMMARY_SUB_TOTAL = 'Summary - SubTotal',
  DEFAULT = 'Default',
}

export const TypographyStyleMapping: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  [TypographyStylesType.HEADINGS_XX_LARGE]: TypographyStyles.Headings.XXLarge,
  [TypographyStylesType.HEADINGS_X_LARGE]: TypographyStyles.Headings.XLarge,
  [TypographyStylesType.HEADINGS_H1]: TypographyStyles.Headings.H1,
  [TypographyStylesType.HEADINGS_H2]: TypographyStyles.Headings.H2,
  [TypographyStylesType.HEADINGS_H3]: TypographyStyles.Headings.H3,
  [TypographyStylesType.HEADINGS_H4]: TypographyStyles.Headings.H4,
  [TypographyStylesType.HEADINGS_H4_SEMIBOLD]: TypographyStyles.Headings.H4SemiBold,
  [TypographyStylesType.HEADINGS_H5]: TypographyStyles.Headings.H5,
  [TypographyStylesType.HEADINGS_H5_STRIKETHROUGH]: TypographyStyles.Headings.H5Strikethrough,
  [TypographyStylesType.BODY_MEDIUM_SEMIBOLD]: TypographyStyles.Body.Medium.SemiBold,
  [TypographyStylesType.BODY_MEDIUM_MEDIUM]: TypographyStyles.Body.Medium.Medium,
  [TypographyStylesType.BODY_MEDIUM_REGULAR]: TypographyStyles.Body.Medium.Regular,
  [TypographyStylesType.BODY_MEDIUM_REGULAR_STRIKETHROUGH]: TypographyStyles.Body.Medium.RegularStrikethrough,
  [TypographyStylesType.BODY_SMALL_SEMIBOLD]: TypographyStyles.Body.Small.SemiBold,
  [TypographyStylesType.BODY_SMALL_MEDIUM]: TypographyStyles.Body.Small.Medium,
  [TypographyStylesType.BODY_SMALL_REGULAR]: TypographyStyles.Body.Small.Regular,
  [TypographyStylesType.BODY_SMALL_REGULAR_STRIKETHROUGH]: TypographyStyles.Body.Small.RegularStrikethrough,
  [TypographyStylesType.BODY_SMALL_LIGHT]: TypographyStyles.Body.Small.Light,
  [TypographyStylesType.BODY_TINY_SEMIBOLD]: TypographyStyles.Body.Tiny.SemiBold,
  [TypographyStylesType.BODY_TINY_MEDIUM]: TypographyStyles.Body.Tiny.Medium,
  [TypographyStylesType.BODY_TINY_REGULAR]: TypographyStyles.Body.Tiny.Regular,
  [TypographyStylesType.LABEL_LARGE_MEDIUM]: TypographyStyles.Label.Large.Medium,
  [TypographyStylesType.LABEL_LARGE_REGULAR]: TypographyStyles.Label.Large.Regular,
  [TypographyStylesType.LABEL_SMALL_SEMIBOLD]: TypographyStyles.Label.Small.SemiBold,
  [TypographyStylesType.LABEL_SMALL_MEDIUM]: TypographyStyles.Label.Small.Medium,
  [TypographyStylesType.LABEL_SMALL_REGULAR]: TypographyStyles.Label.Small.Regular,
  [TypographyStylesType.CTA_LARGE_SEMIBOLD]: TypographyStyles.CallToAction.Large.SemiBold,
  [TypographyStylesType.CTA_LARGE_REGULAR]: TypographyStyles.CallToAction.Large.Regular,
  [TypographyStylesType.CTA_TINY_SEMIBOLD]: TypographyStyles.CallToAction.Tiny.SemiBold,
  [TypographyStylesType.CTA_TINY_REGULAR]: TypographyStyles.CallToAction.Tiny.Regular,
  [TypographyStylesType.BUTTON_SEMIBOLD]: TypographyStyles.Button.SemiBold,
  [TypographyStylesType.SUMMARY_SUB_TOTAL]: TypographyStyles.Summary.SubTotal,
};

export type TypographyType =
  | keyof typeof TypographyStyles.Headings
  | keyof typeof TypographyStyles.Body
  | keyof typeof TypographyStyles.Label
  | keyof typeof TypographyStyles.CallToAction
  | keyof typeof TypographyStyles.Button;
