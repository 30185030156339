import type { ButtonHTMLAttributes, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ArrowRight } from '../../icons';
import { Back, BaseButton, BlackVariant, LightBlueVariant, WhiteVariant } from './Button.styles';

export interface ButtonPropsLabel extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  testId?: string;
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  testId?: string;
}

export function Button({ label, testId, ...props }: ButtonPropsLabel): ReactElement {
  return (
    <BaseButton data-testid={testId} {...props}>
      {label}
    </BaseButton>
  );
}

export function BlackButton({ ...props }: ButtonProps) {
  return <BlackVariant {...props} />;
}

export function WhiteButton({ ...props }: ButtonProps) {
  return <WhiteVariant {...props} />;
}

export function BackButton({ ...props }: ButtonProps) {
  const { t } = useTranslation('lib-global-common');
  const router = useRouter();

  const handleBack = () => router.back();

  return (
    <Back
      {...props}
      onClick={props.onClick || handleBack}
      type="button"
      aria-label={t('register.back')}
      data-testid="backButton"
    >
      <ArrowRight />
      {t('register.back')}
    </Back>
  );
}

export function CustomerServiceButton({ ...props }: ButtonProps) {
  return <LightBlueVariant {...props} />;
}
