import { Locale } from './localeHelper';

export const localeToUpperCase = (locale: string): string => {
  if (locale === Locale.DEFAULT_LOCALE) return locale;

  if (locale) {
    const [lang, country] = locale.split('-');

    return `${lang}-${country.toLocaleUpperCase()}`;
  }

  return Locale.DEFAULT_LOCALE;
};
