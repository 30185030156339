import type { MouseEvent, ReactElement, ReactNode } from 'react';
import Link from 'next/link';
import type { Link as LinkSchema, LocalizedValue } from '@amplience/content-types/typings/p-partials';
import { useLocaleContext } from '../../context/localeContext';
import { Locale } from '../../utils/internationalization/localeHelper';
import { getLocalizedValue } from '../../utils/transformers';

export interface LinkPartialProps extends LinkSchema {
  children?: ReactNode | string;
  className?: string;
  ariaLabel?: string | LocalizedValue;
  externalLink?: string;
  internalLink?: string;
  localizedExternalLink?: LocalizedValue;
  localizedInternalLink?: LocalizedValue;
  id?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
}

export function LinkPartial({
  children,
  className,
  externalLink,
  internalLink,
  localizedExternalLink,
  localizedInternalLink,
  ariaLabel,
  id,
  onClick,
  tabIndex,
  ...props
}: LinkPartialProps): ReactElement {
  const { locale } = useLocaleContext();
  const isOutlet = locale === Locale.STOREFRONT_OUTLET_LOCALE;

  if (externalLink || localizedExternalLink) {
    return (
      <a
        {...props}
        className={className}
        href={(localizedExternalLink as unknown as string) || externalLink}
        aria-label={getLocalizedValue(ariaLabel)}
        id={id}
        onClick={onClick}
        tabIndex={tabIndex}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <Link href={`/${(localizedInternalLink || internalLink) ?? ''}`} locale={isOutlet ? false : locale} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <a
        {...props}
        aria-label={getLocalizedValue(ariaLabel)}
        id={id}
        className={className}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {children}
      </a>
    </Link>
  );
}
