export const Locale = {
  DEFAULT_LOCALE: 'en',
  EN_US: 'en-us',
  EN_IE: 'en-ie',
  EN_CA: 'en-ca',
  FR_CA: 'fr-ca',
  EN_GB: 'en-gb',
  STOREFRONT_OUTLET_LOCALE: 'en-vg',
  CT_OUTLET_LOCALE: 'en-GB-OUTLET',
};

export const LocaleStar2 = {
  EN_FR: 'en-fr',
  FR_FR: 'fr-fr',
  EN_DE: 'en-de',
  DE_DE: 'de-de',
  EN_ES: 'en-es',
  ES_ES: 'es-es',
  EN_IT: 'en-it',
  IT_IT: 'it-it',
  EN_NL: 'en-nl',
  NL_NL: 'nl-nl',
  EN_AT: 'en-at',
  DE_AT: 'de-at',
  EN_BE: 'en-be',
  EN_BG: 'en-bg',
  NL_BE: 'nl-be',
  FR_BE: 'fr-be',
  EN_CH: 'en-ch',
  EN_CY: 'en-cy',
  EN_CZ: 'en-cz',
  DE_CH: 'de-ch',
  FR_CH: 'fr-ch',
  IT_CH: 'it-ch',
  EN_DK: 'en-dk',
  EN_PL: 'en-pl',
  EN_LU: 'en-lu',
  FR_LU: 'fr-lu',
  EN_FI: 'en-fi',
  EN_GR: 'en-gr',
  EN_HU: 'en-hu',
  EN_LI: 'en-li',
  EN_LV: 'en-lv',
  EN_MT: 'en-mt',
  EN_PT: 'en-pt',
  EN_RO: 'en-ro',
  EN_HR: 'en-hr',
  EN_SE: 'en-se',
  EN_SCAND: 'en-SCAND',
  EN_EUROPE: 'en-EUROPE',
  ROE: 'roe',
};
export const STAR1Locales = [
  Locale.EN_GB,
  Locale.EN_IE,
  Locale.EN_US,
  Locale.EN_CA,
  Locale.FR_CA,
  Locale.STOREFRONT_OUTLET_LOCALE,
  Locale.CT_OUTLET_LOCALE,
];

export const STAR2LocalesStandalone = [
  LocaleStar2.EN_FR,
  LocaleStar2.FR_FR,
  LocaleStar2.EN_DE,
  LocaleStar2.DE_DE,
  LocaleStar2.EN_ES,
  LocaleStar2.ES_ES,
  LocaleStar2.EN_IT,
  LocaleStar2.IT_IT,
  LocaleStar2.EN_NL,
  LocaleStar2.NL_NL,
];

export const STAR2LocalesWithMultipleLanguages = [
  LocaleStar2.EN_FR,
  LocaleStar2.FR_FR,
  LocaleStar2.EN_DE,
  LocaleStar2.DE_DE,
  LocaleStar2.EN_ES,
  LocaleStar2.ES_ES,
  LocaleStar2.EN_IT,
  LocaleStar2.IT_IT,
  LocaleStar2.EN_NL,
  LocaleStar2.NL_NL,
  LocaleStar2.EN_AT,
  LocaleStar2.DE_AT,
  LocaleStar2.EN_BE,
  LocaleStar2.NL_BE,
  LocaleStar2.FR_BE,
  LocaleStar2.EN_CH,
  LocaleStar2.DE_CH,
  LocaleStar2.FR_CH,
  LocaleStar2.IT_CH,
  LocaleStar2.EN_DK,
  LocaleStar2.EN_PL,
  LocaleStar2.EN_LU,
  LocaleStar2.FR_LU,
];

export const STAR2LocalesScand = [LocaleStar2.EN_FI, LocaleStar2.EN_SE];

export const STAR1CountryKeys = ['uk', 'roi', 'us', 'ca'];

export const STAR1SupportedGiftCards = [
  Locale.EN_GB,
  Locale.EN_IE,
  Locale.EN_US,
  Locale.STOREFRONT_OUTLET_LOCALE,
  Locale.CT_OUTLET_LOCALE,
];

export const STAR1SupportedEGiftCards = [
  Locale.EN_GB,
  Locale.EN_IE,
  Locale.EN_US,
  Locale.EN_CA,
  Locale.FR_CA,
  Locale.STOREFRONT_OUTLET_LOCALE,
  Locale.CT_OUTLET_LOCALE,
];

export const GTMZoneIds: { [key: string]: string | undefined } = {
  'en-vg': process.env.NEXT_PUBLIC_GTM_ZONE_ID_UK_OUTLET,
  'en-us': process.env.NEXT_PUBLIC_GTM_ZONE_ID_EN_US,
  'en-ie': process.env.NEXT_PUBLIC_GTM_ZONE_ID_EN_IE,
  'en-ca': process.env.NEXT_PUBLIC_GTM_ZONE_ID_EN_CA,
  'fr-ca': process.env.NEXT_PUBLIC_GTM_ZONE_ID_FR_CA,
  'en-gb': process.env.NEXT_PUBLIC_GTM_ZONE_ID_EN_GB,
};

export const siteMapper: { [key: string]: string } = {
  'en-gb': 'UK',
  'en-us': 'US',
  'en-ie': 'Ireland',
  'en-ca': 'Canada',
  'fr-ca': 'Canada',
  'en-vg': 'UK Outlet',
  'en-de': 'Germany',
  'de-de': 'Germany',
  'en-fr': 'France',
  'fr-fr': 'France',
  'en-it': 'Italy',
  'it-it': 'Italy',
  'en-es': 'Spain',
  'es-es': 'Spain',
  'en-nl': 'Netherlands',
  'nl-nl': 'Netherlands',
  roe: 'Rest of Europe',
};

export const countryCodeMapper: { [key: string]: string } = {
  'en-gb': 'GB',
  'en-us': 'US',
  'en-ie': 'IE',
  'en-ca': 'CA',
  'fr-ca': 'CA',
  'en-vg': 'GB',
};

export const countryCodeMapperForStoreLocator: { [key: string]: string } = {
  // Star1 locales
  'en-gb': 'GB',
  'en-us': 'US',
  'en-ie': 'IE',
  'en-ca': 'CA',
  'fr-ca': 'CA',
  'en-vg': 'GB',
  // Star2 locales
  'de-de': 'DE',
  'en-de': 'DE',
  'en-es': 'ES',
  'en-fr': 'FR',
  'en-it': 'IT',
  'en-nl': 'NL',
  'es-es': 'ES',
  'fr-fr': 'FR',
  'it-it': 'IT',
  'nl-nl': 'NL',
  'en-at': 'AT',
  'de-at': 'AT',
  'en-be': 'BE',
  'nl-be': 'BE',
  'fr-be': 'BE',
  'en-ch': 'CH',
  'de-ch': 'CH',
  'fr-ch': 'CH',
  'it-ch': 'CH',
  'en-dk': 'DK',
  'en-pl': 'PL',
  'en-lu': 'LU',
  'fr-lu': 'LU',
  'en-fi': 'FI',
  'en-se': 'SE',
  'en-ro': 'RO',
  'en-pt': 'PT',
  'en-mt': 'MT',
  'en-li': 'LI',
  'en-lv': 'LV',
  'en-hu': 'HU',
  'en-gr': 'GR',
  'en-cz': 'CZ',
  'en-cy': 'CY',
  'en-hr': 'HR',
  'en-bg': 'BG',
};
export const countryCodeLocaleMapperForRedirect: { [key: string]: string } = {
  gb: 'en-gb',
  us: 'en-us',
  ie: 'en-ie',
  ca: 'en-ca',
  de: 'de-de',
  es: 'es-es',
  fr: 'fr-fr',
  it: 'it-it',
  nl: 'nl-nl',
  at: 'en-at',
  be: 'en-be',
  ch: 'en-ch',
  dk: 'en-dk',
  pl: 'en-pl',
  lu: 'en-lu',
  fi: 'en-fi',
  se: 'en-se',
  ro: 'en-ro',
  pt: 'en-pt',
  mt: 'en-mt',
  li: 'en-li',
  lv: 'en-lv',
  hu: 'en-hu',
  gr: 'en-gr',
  cz: 'en-cz',
  cy: 'en-cy',
  hr: 'en-hr',
  bg: 'en-bg',
};
export const languageMapper: { [key: string]: string } = {
  en: 'english',
  fr: 'french',
  de: 'german',
  it: 'italian',
  es: 'spanish',
  nl: 'dutch',
};

export const siteNameMapper: { [key: string]: string } = {
  'en-gb': 'Clarks UK',
  'en-ie': 'Clarks IE',
  'en-vg': 'Clarks UK Outlet',
  'en-us': 'Clarks US',
  'en-ca': 'Clarks CA',
  'fr-ca': 'Clarks CA',
};

export const countryNameMapper: { [key: string]: string } = {
  GB: 'United Kingdom',
  US: 'United States',
  IE: 'Ireland',
  CA: 'Canada',
  DE: 'Germany',
  ES: 'Spain',
  FR: 'France',
  IT: 'Italy',
  NL: 'Netherlands',
};

export const countryNameMapperROE: { [key: string]: string } = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  FI: 'Finland',
  GR: 'Greece',
  HU: 'Hungary',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LU: 'Luxembourg',
  MT: 'Malta',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  CH: 'Switzerland',
};

export const countryTerritoryMapper: { [key: string]: string } = {
  us: 'us',
  ca: 'ca',
  gb: 'gb',
  ie: 'ie',
  fr: 'fr',
  it: 'it',
  es: 'es',
  de: 'de',
  nl: 'nl',
  vg: 'vg',
  roe: 'roe',
};

export const canadaLocales = [Locale.EN_CA, Locale.FR_CA];
export const northAmericaLocales = [...canadaLocales, Locale.EN_US];
