import {
  Locale,
  LocaleStar2,
  STAR1Locales,
  STAR2LocalesScand,
  STAR2LocalesWithMultipleLanguages,
} from './localeHelper';
import { localeToUpperCase } from './localeToUpperCase';

/**
 * Transform NextJS locales to CT locales.
 *
 * @param locale NextJS locale
 * @returns CT locale
 */
export const getCtLocale = (locale: string | undefined): string => {
  if (!locale) {
    return Locale.DEFAULT_LOCALE;
  }

  if (locale.toLowerCase() === Locale.STOREFRONT_OUTLET_LOCALE.toLowerCase()) {
    return Locale.CT_OUTLET_LOCALE;
  }

  if (STAR2LocalesScand.some((star2Locale) => star2Locale.toLowerCase() === locale.toLowerCase())) {
    return LocaleStar2.EN_SCAND;
  }

  if (
    ![...STAR1Locales, ...STAR2LocalesWithMultipleLanguages, ...STAR2LocalesScand].some(
      (starLocale) => starLocale.toLowerCase() === locale.toLowerCase(),
    )
  ) {
    return LocaleStar2.EN_EUROPE;
  }

  return localeToUpperCase(locale);
};
