import type { LocaleType, LanguageType } from '@shared/localisation';

export enum HourFormat {
  HOUR_12 = 'hour12',
  HOUR_24 = 'hour24',
}

export function getCorrectedLocale(locale: LocaleType | LanguageType): string {
  // Safari (14.1.2 atm) doesn't support ar-AE but does support ar-SA
  // return locale === 'ar-AE' ? 'ar-SA' : locale;
  return locale;
}

export function formatNumber(
  locale: LocaleType,
  number: number,
  options: Intl.NumberFormatOptions = { maximumSignificantDigits: 4 },
): string {
  const correctedLocale = getCorrectedLocale(locale);
  const numberFormat = new Intl.NumberFormat(correctedLocale, options);
  return numberFormat.format(number);
}

export function translateNumbers(value: string | number, locale = 'ar'): string {
  if (!value && value !== 0) return '';
  if (locale.includes('ar')) {
    return value.toString().replace(/\d/g, (digit) => '٠١٢٣٤٥٦٧٨٩'[Number(digit)]);
  }

  return value.toString();
}

export function containsArabic(s: string): boolean {
  return /[\u0600-\u06FF]/.test(s);
}

// datetime should be a ISO 8601 string
export function getFormattedTime(datetime: string, locale?: string, hourFormatType?: HourFormat): string {
  let time = datetime.split('T')[1].slice(0, 5);
  if (hourFormatType === HourFormat.HOUR_12) {
    const [hour, minute] = time.split(':');
    const hourInt = +hour;

    const hour12 = hourInt === 12 || hourInt === 0 ? '12' : hourInt % 12;
    const period = hourInt >= 12 ? 'PM' : 'AM';

    time = `${hour12}:${minute} ${period}`;
  }
  return locale && locale.includes('ar') ? translateNumbers(time) : time;
}

export function getFormattedDate(datetime: string, locale?: string): string {
  const date = datetime.split('T')[0];
  return locale && locale.includes('ar') ? translateNumbers(date) : date;
}

export function getSessionTime({
  startDateTime,
  endDateTime,
  status = 'confirmed',
  locale,
  hourFormatType = HourFormat.HOUR_24,
}: {
  startDateTime?: string | null;
  endDateTime?: string | null;
  status?: string;
  locale: string;
  hourFormatType?: HourFormat;
}): string {
  if (status === 'open' || !startDateTime || !endDateTime) {
    return '';
  }

  const startTime = getFormattedTime(startDateTime, locale, hourFormatType);
  const endTime = getFormattedTime(endDateTime, locale, hourFormatType);

  return `${startTime} - ${endTime}`;
}

export function getCountryFromLocale(locale: string) {
  return locale?.split('-')[1]?.toUpperCase();
}

export const outletLocales = ['en-vg', 'en-gb-outlet'];

// The string union type from CustomerMarketingPreferences['country'] cannot be checked at runtime, we have to create an array with the available countries in order to ensure a correct payload
const schemaCountries = [
  'uk',
  'eu',
  'nl',
  'es',
  'us',
  'fr',
  'de',
  'ie',
  'ca',
  'gb',
  'it',
  'at',
  'be',
  'ba',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'ge',
  'gi',
  'gr',
  'hu',
  'il',
  'lv',
  'li',
  'lt',
  'lu',
  'mt',
  'mc',
  'me',
  'mk',
  'no',
  'pl',
  'pt',
  'md',
  'ro',
  'sm',
  'rs',
  'sk',
  'si',
  'se',
  'ch',
  'tr',
  'is',
  'Unknown',
];

export function getCustomerMarketingPreferencesCountry(nextJslocale: string) {
  let country = nextJslocale?.split('-')[1].toLowerCase() || 'Unknown';
  if (country.toString() === 'uk' || country === 'vg') country = 'gb';

  return schemaCountries.includes(country) ? country : 'Unknown';
}

export function getCustomerMarketingPreferencesLanguage(locale: string): string {
  return outletLocales.includes(locale?.toLowerCase()) ? 'en-gb-outlet' : locale;
}

export enum I18nLocale {
  EN_GB = 'en-gb',
  EN_IE = 'en-ie',
  EN_US = 'en-us',
  EN_CA = 'en-ca',
  FR_CA = 'fr-ca',
  EN_VG = 'en-vg',
  DE_DE = 'de-de',
  EN_DE = 'en-de',
  FR_FR = 'fr-fr',
  EN_FR = 'en-fr',
  IT_IT = 'it-it',
  EN_IT = 'en-it',
  ES_ES = 'es-es',
  EN_ES = 'en-es',
  NL_NL = 'nl-nl',
  EN_NL = 'en-nl',
  DE_AT = 'de-at',
  EN_AT = 'en-at',
  DE_CH = 'de-ch',
  FR_CH = 'fr-ch',
  IT_CH = 'it-ch',
  EN_CH = 'en-ch',
  IT_SM = 'it-sm',
  EN_SM = 'en-sm',
  EN_PL = 'en-pl',
  NL_BE = 'nl-be',
  FR_BE = 'fr-be',
  EN_BE = 'en-be',
  EN_DK = 'en-dk',
  FR_LU = 'fr-lu',
  EN_LU = 'en-lu',
  EN_BA = 'en-ba',
  EN_BG = 'en-bg',
  EN_HR = 'en-hr',
  EN_CY = 'en-cy',
  EN_CZ = 'en-cz',
  EN_EE = 'en-ee',
  EN_FI = 'en-fi',
  EN_GE = 'en-ge',
  EN_GI = 'en-gi',
  EN_GR = 'en-gr',
  EN_HU = 'en-hu',
  EN_IS = 'en-is',
  EN_LV = 'en-lv',
  EN_LI = 'en-li',
  EN_LT = 'en-lt',
  EN_MT = 'en-mt',
  EN_MC = 'en-mc',
  EN_ME = 'en-me',
  EN_MK = 'en-mk',
  EN_NO = 'en-no',
  EN_PT = 'en-pt',
  EN_MD = 'en-md',
  EN_RO = 'en-ro',
  EN_RS = 'en-rs',
  EN_SK = 'en-sk',
  EN_SI = 'en-si',
  EN_SE = 'en-se',
  EN_TR = 'en-tr',
}

export const deactivatedLocales: I18nLocale[] = [
  I18nLocale.EN_EE,
  I18nLocale.EN_GE,
  I18nLocale.EN_GI,
  I18nLocale.EN_IS,
  I18nLocale.EN_LT,
  I18nLocale.EN_MD,
  I18nLocale.EN_MC,
  I18nLocale.EN_ME,
  I18nLocale.EN_MK,
  I18nLocale.EN_NO,
  I18nLocale.EN_SM,
  I18nLocale.EN_RS,
  I18nLocale.EN_SK,
  I18nLocale.EN_SI,
  I18nLocale.EN_TR,
  I18nLocale.EN_BA,
  I18nLocale.IT_SM,
];

const supportedLocales = new Set(Object.values(I18nLocale).filter((locale) => !deactivatedLocales.includes(locale)));

/**
 * Determines whether or not a given locale is a supported store locale
 */
export const isSupportedStoreLocale = (locale: string): boolean => {
  return supportedLocales.has(locale.toLowerCase() as I18nLocale);
};

/**
 * Determines whether or not a given locale is a store locale which was deactivated
 */
export const isDeactivatedStoreLocale = (locale: string) => {
  return deactivatedLocales.includes(locale.toLowerCase() as I18nLocale);
};
