import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetCustomerQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'Customer';
    id: string;
    customerNumber?: string | null;
    email: string;
    newEmail?: string | null;
    phone?: string | null;
    signupRegion?: string | null;
    channel?: string | null;
    gender?: string | null;
    areCustomerCardDetailsSaved?: boolean | null;
    firstName: string;
    lastName: string;
    languagePreference?: string | null;
    defaultBillingAddressId?: string | null;
    defaultShippingAddressId?: string | null;
    registeredFrom?: string | null;
    dateOfBirth?: string | null;
    savedStoresIds?: Array<string> | null;
    defaultStoreId?: string | null;
    shoeSize?: string | null;
    shoeWidth?: string | null;
    countryAlias?: string | null;
    addresses?: Array<{
      __typename?: 'Address';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      company?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      postalCode?: string | null;
      country: string;
      state?: string | null;
      key?: string | null;
      countryAlias?: string | null;
    }> | null;
  };
};

export const GetCustomerDocument = gql`
  query getCustomer {
    getCustomer {
      id
      customerNumber
      email
      newEmail
      phone
      signupRegion
      channel
      gender
      areCustomerCardDetailsSaved
      firstName
      lastName
      languagePreference
      phone
      defaultBillingAddressId
      defaultShippingAddressId
      addresses {
        id
        firstName
        lastName
        phone
        company
        line1
        line2
        city
        postalCode
        country
        state
        key
        countryAlias
      }
      registeredFrom
      dateOfBirth
      savedStoresIds
      defaultStoreId
      shoeSize
      shoeWidth
      countryAlias
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
