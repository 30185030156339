import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { storeKeyMapper } from '@shared/utils/src/ct';
import { getCountryFromLocale } from '@shared/utils/src/i18n';
import { fromCents } from '@shared/utils/src/price/money';
import { START_CSA_SESSION_TOKEN } from '../auth';
import type { Cart } from '../codegen/types';
import { DiscountCodeErrorCode } from '../codegen/types';
import { DiscountExpiredModal } from '../globalComponents/modal';
import type { GetCartQueryVariables } from '../graphqlDocument/cart/getCart.generated';
import { useGetCartLazyQuery } from '../graphqlDocument/cart/getCart.generated';
import { useAddCsaTokenMutation } from '../graphqlDocument/csa/addCsaToken.generated';
import { useDisableBodyScroll, useNRBASession } from '../hooks';
import { GiftCardType } from '../types/giftCard';
import { AttributeName } from '../types/newrelic';
import {
  CartStorageKeys,
  createAnchorTagHref,
  getBasketPageLink,
  getCtLocale,
  getCurrencySymbol,
  getItem,
  getNumberOfItems,
  removeItem,
  setCartLocalStorageItems,
} from '../utils';
import { setNewRelicAttributes } from '../utils/newrelic';
import { useLocaleContext } from './localeContext';
import { useStaticContext } from './staticContext';
import { useUserContext } from './userContext';

type CartContextType = {
  miniCart: Cart | Record<string, never>;
  setMiniCart: (miniCart: Cart) => void;
  refetchCart: (variables?: GetCartQueryVariables) => void;
  showConfirmationMessage: boolean;
  setShowConfirmationMessage: (showConfirmation: boolean) => void;
  anonymousId?: string | null;
  setAnonymousId: (anonId: string) => void;
  cartId: string;
  setCartId: (cartId: string) => void;
  guid: string;
  setGuid: (guid: string) => void;
  isCartLoading: boolean;
  hasLastItemDeleted: boolean;
  setHasLastItemDeleted: (hasLastItemDeleted: boolean) => void;
  checkoutSecurely: boolean;
  setCheckoutSecurely: (checkoutSecurely: boolean) => void;
  isCartEmpty: boolean;
  basketTotal: string;
  basketTotalCent: number;
  currency: string;
  itemsInCart: number;
  isMaxItemsReached: boolean;
  isBasketValueExceeded: boolean;
  isMaxItemsReachedOrBasketValueExceeded: () => void;
  isLineItemsEmpty: boolean;
  isSkuCapReached: boolean;
  isSkuCapExceeded: boolean;
  cartInputVariables: GetCartQueryVariables | undefined;
  shouldUpdateDeliveryOptions: boolean;
  setShouldUpdateDeliveryOptions: (newValue: boolean) => void;
};

const CartContext = createContext<CartContextType>({
  miniCart: {},
  setMiniCart: () => {},
  refetchCart: () => {},
  showConfirmationMessage: false,
  setShowConfirmationMessage: () => {},
  anonymousId: '',
  setAnonymousId: () => {},
  cartId: '',
  setCartId: () => {},
  guid: '',
  setGuid: () => {},
  isCartLoading: true,
  hasLastItemDeleted: false,
  setHasLastItemDeleted: () => {},
  checkoutSecurely: false,
  setCheckoutSecurely: () => {},
  isCartEmpty: false,
  basketTotal: '0',
  basketTotalCent: 0,
  currency: '',
  itemsInCart: 0,
  isMaxItemsReached: false,
  isBasketValueExceeded: false,
  isMaxItemsReachedOrBasketValueExceeded: () => {},
  isLineItemsEmpty: false,
  isSkuCapReached: false,
  isSkuCapExceeded: false,
  cartInputVariables: undefined,
  shouldUpdateDeliveryOptions: false,
  setShouldUpdateDeliveryOptions: () => {},
});

export const useCartContext = () => useContext(CartContext);

export function CartContextProvider({ children }: { children: ReactNode }) {
  const { locale } = useLocaleContext();
  const { storeData } = useStaticContext();
  const { productQuantityCap, basketValueCap, skuCap } = { ...storeData };
  const { customerLoading, isUserLoggedIn } = useUserContext();

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [miniCart, setMiniCart] = useState<Cart | Record<string, never>>({});
  const [anonymousId, setAnonymousId] = useState<string>('');
  const [cartId, setCartId] = useState<string>('');
  const [orderValue, setOrderValue] = useState<number | null>(null);
  const [guid, setGuid] = useState<string>('');
  const [hasLastItemDeleted, setHasLastItemDeleted] = useState(false);
  const [checkoutSecurely, setCheckoutSecurely] = useState(false);
  const [isRetrievingIds, setIsRetrievingIds] = useState(true);
  const [shouldUpdateDeliveryOptions, setShouldUpdateDeliveryOptions] = useState(false);
  const { lineItems, total, currency: localeCurrency } = { ...miniCart };

  const basketTotal = fromCents(total) || '0';
  const currency = getCurrencySymbol(localeCurrency);
  const itemsInCart = getNumberOfItems(lineItems);
  const isMaxItemsReached = productQuantityCap ? itemsInCart > productQuantityCap : false;
  const isBasketValueExceeded = basketValueCap ? Number(basketTotal) > basketValueCap : false;
  const isLineItemsEmpty = miniCart?.lineItems?.length < 1;
  const isSkuCapReached = !!lineItems?.find(({ quantity }) => skuCap && quantity >= skuCap);
  const isSkuCapExceeded = !!lineItems?.find(({ quantity }) => skuCap && quantity > skuCap);
  const router = useRouter();
  const isConfirmationPage = router?.asPath.split('?')[0] === '/checkout/confirmation';

  // '/checkout' will cover STAR2 locales, other URLs will cover the STAR1 locales
  const shouldRecalculate = ['/checkout', '/basket', '/cart', '/checkout/delivery', '/checkout/payment'].includes(
    // `asPath` is the only `router`s property which is changing together with URL change.
    // However, it includes search query. We need to get rid of search query to be sure of clean URL
    router.asPath.split('?')[0],
  );

  const [addCsaTokenMutation] = useAddCsaTokenMutation();

  const updateMiniCart: CartContextType['setMiniCart'] = useCallback((cart) => {
    const inactiveDiscountCodes = cart?.errors
      ?.filter((error) => {
        if (error?.__typename === 'DiscountCodeError') {
          return [DiscountCodeErrorCode.NOT_VALID, DiscountCodeErrorCode.NOT_ACTIVE].includes(error?.errorCode);
        }
        return false;
      })
      .map<string>((error: any) => error.discountCode);

    const activeDiscountCodes = cart?.discountCodes?.filter(
      (discount) => discount?.discountCode && !inactiveDiscountCodes?.includes(discount?.discountCode),
    );

    setMiniCart({
      ...cart,
      discountCodes: activeDiscountCodes,
      hasPhysicalGiftCard: cart.lineItems?.some((item) => item.productTypeKey === GiftCardType.GIFT_CARD),
    });
  }, []);

  const nrbaContextHeader = useNRBASession();

  const [getCartQuery, { loading: isGetCartLoading, refetch, variables: cartInputVariables }] = useGetCartLazyQuery({
    ...nrbaContextHeader,
    onCompleted: ({ getCart: cart }) => {
      updateMiniCart((cart || {}) as Cart);
      setCartId(cart?.id ?? '');
      setGuid(cart?.guid ?? '');
      setOrderValue(+fromCents(cart?.total));
      setAnonymousId(cart?.anonymousId ?? '');
      setCartLocalStorageItems(locale, cart?.id ?? '', cart?.anonymousId ?? '', cart?.guid ?? '');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const fetchCart = async () => {
      setIsRetrievingIds(true);

      const cartIdInStorage = getItem(
        `${CartStorageKeys.CART_ID}_${getCountryFromLocale(locale)}`,
        true,
        'localStorage',
      );
      const cartAnonIdInStorage = getItem(
        `${CartStorageKeys.CART_ANON_ID}_${getCountryFromLocale(locale)}`,
        true,
        'localStorage',
      );
      const cartGuidInStorage = getItem(
        `${CartStorageKeys.GUID_ID}_${getCountryFromLocale(locale)}`,
        true,
        'localStorage',
      );

      const startCsaSessionToken = getItem(START_CSA_SESSION_TOKEN);

      if (cartIdInStorage) {
        setCartId(cartIdInStorage);
        setAnonymousId(cartAnonIdInStorage);
        setGuid(cartGuidInStorage);
      }

      if (isConfirmationPage) {
        setIsRetrievingIds(false);
      } else {
        await getCartQuery({
          variables: {
            input: {
              cartId: cartIdInStorage ?? '',
              anonymousId: cartAnonIdInStorage ?? '',
              storeKey: storeKeyMapper(locale),
              locale: getCtLocale(locale),
              ...(shouldRecalculate && { recalculate: true }),
            },
          },
        });

        if (cartIdInStorage && startCsaSessionToken) {
          try {
            await addCsaTokenMutation({
              variables: {
                input: {
                  cartId: cartIdInStorage,
                  anonymousId: cartAnonIdInStorage ?? '',
                  csaToken: startCsaSessionToken,
                  locale: getCtLocale(locale),
                  storeKey: storeKeyMapper(locale),
                },
              },
            });
            removeItem(START_CSA_SESSION_TOKEN);
          } catch (e) {
            console.error(e);
          }
        }

        setIsRetrievingIds(false);
      }
    };

    if (customerLoading) return;

    fetchCart();
  }, [
    locale,
    getCartQuery,
    customerLoading,
    isConfirmationPage,
    addCsaTokenMutation,
    isUserLoggedIn,
    shouldRecalculate,
  ]);

  useDisableBodyScroll(checkoutSecurely);

  const isCheckoutFlow = getItem(CartStorageKeys.CHECKOUT_FLOW);

  const isMaxItemsReachedOrBasketValueExceeded = useCallback(() => {
    if ((!isCheckoutFlow && (isMaxItemsReached || isBasketValueExceeded || isSkuCapExceeded)) || isLineItemsEmpty) {
      window.location.href = createAnchorTagHref({ locale, path: getBasketPageLink(locale) });
    }
  }, [isCheckoutFlow, isBasketValueExceeded, isMaxItemsReached, locale, isLineItemsEmpty, isSkuCapExceeded]);

  const value = useMemo(
    () => ({
      miniCart,
      setMiniCart: updateMiniCart,
      refetchCart: refetch,
      showConfirmationMessage,
      setShowConfirmationMessage,
      anonymousId,
      setAnonymousId,
      cartId,
      setCartId,
      guid,
      setGuid,
      isCartLoading: isGetCartLoading || isRetrievingIds,
      hasLastItemDeleted,
      setHasLastItemDeleted,
      checkoutSecurely,
      setCheckoutSecurely,
      isCartEmpty: (!miniCart?.lineItems || miniCart?.lineItems?.length === 0) && !hasLastItemDeleted,
      basketTotal,
      basketTotalCent: total,
      currency,
      itemsInCart,
      isMaxItemsReached,
      isBasketValueExceeded,
      isMaxItemsReachedOrBasketValueExceeded,
      isLineItemsEmpty,
      isSkuCapReached,
      isSkuCapExceeded,
      cartInputVariables,
      shouldUpdateDeliveryOptions,
      setShouldUpdateDeliveryOptions,
    }),
    [
      miniCart,
      updateMiniCart,
      refetch,
      showConfirmationMessage,
      anonymousId,
      cartId,
      guid,
      isGetCartLoading,
      isRetrievingIds,
      hasLastItemDeleted,
      checkoutSecurely,
      basketTotal,
      total,
      currency,
      itemsInCart,
      isMaxItemsReached,
      isBasketValueExceeded,
      isMaxItemsReachedOrBasketValueExceeded,
      isLineItemsEmpty,
      isSkuCapReached,
      isSkuCapExceeded,
      cartInputVariables,
      shouldUpdateDeliveryOptions,
    ],
  );

  useEffect(() => {
    setNewRelicAttributes({
      [AttributeName.ORDER_ID]: cartId || null,
      [AttributeName.ORDER_VALUE]: orderValue,
      [AttributeName.ORDER_CART_ID]: anonymousId || null,
    });
  }, [orderValue, cartId, anonymousId]);

  return (
    <CartContext.Provider value={value}>
      {children}
      <DiscountExpiredModal />
    </CartContext.Provider>
  );
}
