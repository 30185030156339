import { createContext, useContext } from 'react';
import { Locale } from '../utils/internationalization/localeHelper';

export interface LocaleContextObject {
  locale: string;
}

export const LocaleContext = createContext<LocaleContextObject>({
  locale: Locale.DEFAULT_LOCALE,
});

export const useLocaleContext = () => useContext(LocaleContext);
