import { Locale } from '../internationalization';

export const createAnchorTagHref = ({ locale, path }: { path: string; locale?: string }) => {
  const lowerCaseLocale = locale?.toLowerCase();
  const isOutlet =
    lowerCaseLocale === Locale.STOREFRONT_OUTLET_LOCALE || lowerCaseLocale === Locale.CT_OUTLET_LOCALE.toLowerCase();
  const relativePath = path.startsWith('/') ? path.slice(1, path.length) : path;

  if (isOutlet) {
    return `/${relativePath}`;
  }

  return `/${lowerCaseLocale}/${relativePath}`;
};
