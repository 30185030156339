import { getCountryFromLocale } from '@shared/utils/src/i18n';
import type { LineItem } from '../codegen/types';
import { Locale } from './internationalization/localeHelper';
import { setItem } from './storage';

export enum CartStorageKeys {
  CART_ID = 'cart-id',
  CART_ANON_ID = 'cart-anon-id',
  CHECKOUT_FLOW = 'checkout-flow',
  GUID_ID = 'guid-id',
}

export const getNumberOfItems = (items: LineItem[] = []) =>
  items.length > 0 ? items.map((item) => +item.quantity).reduce((prev, curr) => prev + curr) : 0;

export const basketLocales = [Locale.EN_GB, Locale.STOREFRONT_OUTLET_LOCALE, Locale.EN_IE];

export const getBasketPageLink = (locale: string | undefined) =>
  basketLocales.includes(locale ?? Locale.DEFAULT_LOCALE) ? 'basket' : 'cart';

export const setCartLocalStorageItems = (
  locale: string,
  cartId: string | null,
  anonId: string | null,
  guid: string | null,
) => {
  setItem(`${CartStorageKeys.CART_ID}_${getCountryFromLocale(locale)}`, cartId, true, 'localStorage');
  setItem(`${CartStorageKeys.CART_ANON_ID}_${getCountryFromLocale(locale)}`, anonId, true, 'localStorage');
  setItem(`${CartStorageKeys.GUID_ID}_${getCountryFromLocale(locale)}`, guid, true, 'localStorage');
};
