import { useTranslation } from 'next-i18next';
import { BlackButton, DialogPortal, DialogRoot } from '../../baseComponents';
import { Tick, Warning } from '../../icons';
import { StateModalResult } from '../../types';
import {
  ButtonContainer,
  DialogContentWrapper,
  DialogOverlayComponent,
  IconWrapper,
  LinkPartialCart,
  LinkPartialCartWrapper,
  TextInfo,
} from './StateModal.styles';

export type StateModalProps = {
  isOverLayOpen: boolean;
  handleOpenOverlay: () => void;
  message?: string;
  result?: StateModalResult;
  warningMessage: string;
  closeOnContentClick?: boolean;
  isStar2?: boolean;
  isCTA?: boolean;
};

export function StateModal({
  isOverLayOpen,
  handleOpenOverlay,
  message,
  result = StateModalResult.SUCCESS,
  warningMessage,
  closeOnContentClick = false,
  isStar2,
  isCTA,
}: StateModalProps) {
  const { t } = useTranslation(['app-orders-common']);

  const handleOnContentClick = () => {
    if (closeOnContentClick && isOverLayOpen) {
      handleOpenOverlay();
    }
  };

  const handleOnOpenAutoFocus = (e: Event) => {
    e.preventDefault();
  };

  return (
    <DialogRoot open={isOverLayOpen} onOpenChange={handleOpenOverlay}>
      <DialogPortal>
        <DialogOverlayComponent />
        <DialogContentWrapper
          $result={result}
          role="status"
          onClick={handleOnContentClick}
          onOpenAutoFocus={handleOnOpenAutoFocus}
          isWithCloseButton={false}
          data-testid="modalMessage"
        >
          <>
            <IconWrapper $result={result}>
              {result === StateModalResult.SUCCESS ? (
                <Tick aria-hidden data-testid="successIcon" />
              ) : (
                <Warning aria-hidden data-testid="warningIcon" />
              )}
            </IconWrapper>
            <TextInfo>{result === StateModalResult.WARNING ? warningMessage : message}</TextInfo>
          </>

          {isCTA && (
            <ButtonContainer>
              <BlackButton onClick={handleOpenOverlay}>{t('checkout.warning.continue')}</BlackButton>
            </ButtonContainer>
          )}
          {isStar2 && (
            <LinkPartialCartWrapper>
              <LinkPartialCart internalLink="cart">{t('checkout.warning.ok')}</LinkPartialCart>
            </LinkPartialCartWrapper>
          )}
        </DialogContentWrapper>
      </DialogPortal>
    </DialogRoot>
  );
}

export default StateModal;
